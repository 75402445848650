import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useBound } from '@/contexts/BoundContext';
import { Button, message, Tooltip } from 'antd';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AirbnbSetting = ({ token, client }) => {
  const { roomId, setRoomId, messageId, setMessageId } = useBound();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [reloadingBtn, setReloadingBtn] = useState(false);

  const fetchOrderInfo = async () => {
    setLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 2000));
      // 1. POST to open the server
      const postResponse = await axios.post(`${SERVER_URL}/private/module/rpa/airbnb/order_info/${roomId}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: {
          message_id: messageId
        }
      });
      console.log('POST response:', postResponse);
      await new Promise(resolve => setTimeout(resolve, 2000));
      // 2. GET order_info after POST
      const getResponse = await axios.get(`${SERVER_URL}/private/module/rpa/airbnb/order_info/${roomId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          message_id: messageId
        }
      });
      console.log('First GET response:', getResponse);
      setOrderData(getResponse.data);
      setReloadingBtn(false)

      // 3. wait 2 sec before attempting second GET
      await new Promise(resolve => setTimeout(resolve, 5000));

      // 4. GET order_info again after 2 seconds
      const secondGetResponse = await axios.get(`${SERVER_URL}/private/module/rpa/airbnb/order_info/${roomId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          message_id: messageId
        }
      });
      console.log('Second GET response:', secondGetResponse);
      setOrderData(secondGetResponse.data);
      setReloadingBtn(false)
    } catch (error) {
      setReloadingBtn(true)
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    setOrderData(null);
    fetchOrderInfo();
  }, [messageId, roomId]);

  const getOrderDetailByKeyword = (details, keyword) => {
    const index = details ? details.findIndex(detail => detail.includes(keyword)) : -1;
    return index !== -1 && details[index + 1] ? details[index + 1] : '';
  };

  return (
    <>
      <div className='p-3'>
        <div className="flex justify-between font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
          <button onClick={() => fetchOrderInfo()}>預定詳情</button>
          {reloadingBtn ? (
            <Tooltip title="重新連線中...點擊以重整">
              <div className="flex flex-row items-center gap-2 ml-2 cursor-pointer" onClick={fetchOrderInfo}>
                <div className="w-4 h-4 animate-spin rounded-full border-t-2 border-b-2 border-yellow-500"></div>
                <span className="text-yellow-500">重整</span>
              </div>
            </Tooltip>
          ) : ''}
        </div>
        <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full rounded-[11px]">
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">客戶名稱</div>
            <div className="text-[14px] font-[400]">{orderData?.result?.order_summary?.[1] || ''}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">房間名稱</div>
            <div className="text-[14px] font-[400]">{orderData?.result?.order_summary?.[2] || ''}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">價格</div>
            <div className="text-[14px] font-[400]">{orderData?.result?.order_summary?.[4] || ''}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">入房</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '入住')}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">退房</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '退房')}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">預定日期</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '預定')}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">驗證碼</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '驗證')}</div>
          </div>
        </div>
      </div>
    </>
  );


}

export default AirbnbSetting