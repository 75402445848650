import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import { message } from 'antd';

const IntegrationContext = createContext();

export const IntegrationProvider = ({ children }) => {
  const { token } = useAuth();
  const [roomIntegrations, setRoomIntegrations] = useState({});

  // fetch function to sync with backend
  const fetchIntegrations = async (roomId) => {
    try {
      const url = `https://api.scfg.io/private/module/rpa/airbnb/account_info/${roomId}`;
      
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        const integrations = response.data.body;
        setRoomIntegrations(prevState => ({
          ...prevState,
          [roomId]: [integrations]
        }));
      }
    } catch (error) {
      console.error('Failed to fetch Airbnb Integrations:', error);
    }
  };

  // add
  const addIntegration = async (roomId, integration) => {
    try {
      const url = `https://api.scfg.io/private/module/rpa/airbnb/account_info/${roomId}?email=${encodeURIComponent(integration.email)}&password=${encodeURIComponent(integration.password)}`;

      const response = await axios.post(url, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        setRoomIntegrations(prevState => ({
          ...prevState,
          [roomId]: [...(prevState[roomId] || []), integration]
        }));
        return true; 
      }
    } catch (error) {
      console.error('Failed to add or update Airbnb Integration:', error);
      if (error.response && error.response.data) {
        message.error("無法增加或更新 Airbnb");
      } else {
        message.error("無法增加或更新 Airbnb");
      }
      return false; 
    }
  };

  // update
  const updateIntegration = async (roomId, index, updatedIntegration) => {
    setRoomIntegrations(prevState => ({
      ...prevState,
      [roomId]: prevState[roomId].map((integration, i) => 
        i === index ? updatedIntegration : integration
      )
    }));
  };

  // rm
  const removeIntegration = (roomId, index) => {
    setRoomIntegrations(prevState => ({
      ...prevState,
      [roomId]: prevState[roomId].filter((_, i) => i !== index)
    }));
  };

  // get
  const getIntegration = (roomId, index) => {
    return roomIntegrations[roomId]?.[index];
  };

  useEffect(() => {
    const roomIds = Object.keys(roomIntegrations);
    roomIds.forEach(roomId => {
      fetchIntegrations(roomId);
    });
  }, []); 

  return (
    <IntegrationContext.Provider value={{ roomIntegrations, addIntegration, updateIntegration, removeIntegration, getIntegration, fetchIntegrations }}>
      {children}
    </IntegrationContext.Provider>
  );
};

// hook
export const useIntegration = () => {
  return useContext(IntegrationContext);
};
