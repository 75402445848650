import React, { useState } from 'react'
import axios from 'axios';

import { Form, Input, Button, notification, Tooltip } from "antd";

import approveIcon from './assets/approve.png'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const COMPANY_ID = process.env.REACT_APP_COMPANY_ID;

const openNotification = (title, description) => {
  notification.open({
    message: title,
    description: description,
    placement: "bottomRight",
  });
};

const Register = () => {
  const [success, setSuccess] = useState(false)
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  // const [password, setPassword] = useState("")
  // const [passwordCheck, setPasswordCheck] = useState("")
  const [nickname, setNickname] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    // internal checking
    if (!email.includes("@")) {
      openNotification("Email 格式錯誤", "請輸入正確的 Email 格式")
      setLoading(false)
      return
    }

    // check username pattern
    if (!/^[A-Za-z\d._!#-]*$/.test(username)) {
      openNotification("帳號格式錯誤", "特殊符號支援 . _ ! # - 五種。");
      setLoading(false);
      return;
    }

    try {
      await axios.get(SERVER_URL + "/public/auth/check/email/" + email + "?company_id=" + COMPANY_ID)
    } catch (error) {
      if (error.response.status === 409) {
        openNotification("Email 已被註冊", "請輸入其他 Email")
        setLoading(false)
        return
      }
    }

    // check username
    try {
      await axios.get(SERVER_URL + "/public/auth/check/username/" + username)
    } catch (error) {
      if (error.response.status === 409) {
        openNotification("帳號已被註冊", "請輸入其他帳號")
        setLoading(false)
        return
      }
    }

    // subimit
    try {
      await axios.post(SERVER_URL + '/public/auth/register/' + COMPANY_ID, {
        username: username,
        nickname: nickname,
        email: email,
      });
      setSuccess(true)
    } catch (error) {
      console.log(error)
      openNotification("註冊失敗", "請洽系統管理員")
    }

    setLoading(false)
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="flex flex-col items-center justify-center w-80 gap-4">
        <a href="/login" className="flex flex-row juystify-start w-full hover:underline cursor-pointer">
          返回
        </a>
        {
          success ?
            <div className="flex flex-col items-center gap-8">
              <img src={approveIcon} className="h-36 w-36" alt="approve" />
              <div>
                恭喜您註冊完成！請進入Email中進行驗證
              </div>
            </div>
            :
            <div className="flex flex-col items-center">
              <div className="text-2xl font-bold mb-4">
                註冊
              </div>
              <Form
                layout="vertical"
                style={{
                  width: "320px",
                }}
              >
                <Form.Item label="Email">
                  <Input onChange={e => setEmail(e.target.value)} disabled={loading} />
                </Form.Item>
                <Tooltip title="特殊符號僅支援 . _ ! # - 五種。">
                  <Form.Item label="帳號">
                    <Input onChange={e => setUsername(e.target.value)} disabled={loading} />
                  </Form.Item>
                </Tooltip>
                {/* <Form.Item label="密碼">
                  <Input.Password onChange={e => setPassword(e.target.value)} disabled={loading} />
                </Form.Item>
                <Form.Item label="再次輸入密碼">
                  <Input.Password onChange={e => setPasswordCheck(e.target.value)} disabled={loading} />
                </Form.Item> */}
                <Form.Item label="暱稱">
                  <Input onChange={e => setNickname(e.target.value)} disabled={loading} />
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleSubmit}
                    disabled={!email || !username || !nickname}
                    type="primary"
                    className="w-full mt-4"
                    loading={loading}
                  >
                    註冊
                  </Button>
                </Form.Item>
              </Form>
            </div>
        }
      </div>
    </div>
  )
}

export default Register