import axios from 'axios';
const getToken = () => {
  return localStorage.getItem('token');
}
const request = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 5000,
});

request.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config
}, (error) => {
  return Promise.reject(error);
})

request.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  return Promise.reject(error);
}
)

export default request;