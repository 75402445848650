import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, InputNumber, Input, Checkbox, message, Spin, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useUser } from '@/contexts/UserProvider';
import { useAuth } from '@/contexts/AuthProvider'

import MgntAvatar from './MgntAvatar'


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ROLES = [
  {
    "value": -2,
    "label": "虛擬部門管理者"
  },
  {
    "value": -1,
    "label": "測試員"
  },
  {
    "value": 0,
    "label": "一般員工"
  },
  {
    "value": 1,
    "label": "資深員工"
  },
  {
    "value": 2,
    "label": "初階管理者"
  },
  {
    "value": 3,
    "label": "中階管理者"
  },
  {
    "value": 4,
    "label": "高階管理者"
  }
]

const AcMgnt = ({ selectedUserId }) => {
  const [loading, setLoading] = useState(false);
  const { userRoleId, token } = useAuth();
  const [user, setUser] = useState({
    role_id: 0,
    role_name: "",
    photo_url: null,
    expired_at: "",
    id: "",
    enabled: true,
    username: "",
    email: "",
    nickname: "",
    department_id: "",
    department_name: "",
    company_id: "",
    company_name: "",
    chatroom_limit: 0,
    created_rooms: [],
    joined_rooms: []
  });
  const [oldUser, setOldUser] = useState({
    role_id: 0,
    role_name: "",
    photo_url: null,
    expired_at: "",
    id: "",
    enabled: true,
    username: "",
    email: "",
    nickname: "",
    department_id: "",
    department_name: "",
    company_id: "",
    company_name: "",
    chatroom_limit: 0,
    created_rooms: [],
    joined_rooms: []
  })
  const [departments, setDepartments] = useState([])
  // const [isDifferent, setIsDifferent] = useState(false)

  useEffect(() => {
    fetchUser()
    // fetchCompany()
    fetchDepartments()
  }, [])

  const fetchDepartments = async () => {
    setLoading(true)
    try {
      const response = await axios.get(SERVER_URL + '/private/company/departments/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setDepartments(response.data)
    } catch (error) {
      message.open({
        type: 'error',
        content: error.response.data.detail,
        duration: 3
      });
    } finally {
      setLoading(false)
    }
  }

  const fetchCompany = async () => {
    setLoading(true)
    try {
      const response = await axios.get(SERVER_URL + '/private/company/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    } catch (error) {
      message.open({
        type: 'error',
        content: error.response.data.detail,
        duration: 3
      });
    } finally {
      setLoading(false)
    }
  }


  const fetchUser = async () => {
    setLoading(true)
    try {
      const response = await axios.get(SERVER_URL + '/private/user/' + selectedUserId, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setUser(response.data)
      setOldUser(response.data)
    } catch (error) {
      message.open({
        type: 'error',
        content: error.response.data.detail,
        duration: 3
      });
    } finally {
      setLoading(false)
    }
  }

  // const detectDifferent = () => {
  //   setIsDifferent(JSON.stringify(user)!== JSON.stringify(oldUser))
  // }

  const checkValid = () => {
    let isValid = true

    // check chatroom limit is valid number
    if (!Number.isInteger(user.chatroom_limit)) {
      message.open({
        type: 'error',
        content: '聊天室數量必須為整數',
        duration: 3
      });
      isValid = false
    }

    if (departments.find(d => d.id === user.department_id)?.chatroom_limit - departments.find(d => d.id === user.department_id)?.num_of_rooms - user.chatroom_limit < 0) {
      message.open({
        type: 'error',
        content: '部門可分配房間數不足',
        duration: 3
      });
      isValid = false
    }

    return isValid
  }

  // TODO: api connection
  const handleSave = async () => {
    // if no different
    if (JSON.stringify(user) === JSON.stringify(oldUser)) {
      message.open({
        type: 'warning',
        content: '未有任何修改',
        duration: 3
      });
      return
    }

    // check validility
    if (!checkValid()) {
      return
    }

    // enabled
    if (user.enabled !== oldUser.enabled) {
      try {
        if (user.enabled) {
          await axios.patch(SERVER_URL + "/private/user/enable/" + oldUser.id,
            {},
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            }
          )
        } else {
          await axios.patch(SERVER_URL + "/private/user/disable/" + oldUser.id,
            {},
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            }
          )
        }
      } catch (error) {
        message.open({
          type: 'error',
          content: error.response.data.detail,
          duration: 3
        });
        return
      }
    }

    // chatroom_limit
    if (user.chatroom_limit !== oldUser.chatroom_limit) {
      let num = Math.abs(user.chatroom_limit - oldUser.chatroom_limit)
      try {
        if (user.chatroom_limit > oldUser.chatroom_limit) {
          await axios.patch(SERVER_URL + "/private/user/chatroom_limit/grant/" + oldUser.id + "?limit=" + num,
            {},
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            }
          )
        } else {
          await axios.patch(SERVER_URL + "/private/user/chatroom_limit/take_back/" + oldUser.id + "?limit=" + num,
            {},
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            }
          )
        }
      } catch (error) {
        message.open({
          type: 'error',
          content: error.response.data.detail,
          duration: 3
        });
        return
      }
    }

    // role_id
    if (user.role_id !== oldUser.role_id && user.role_id) {
      try {
        await axios.patch(SERVER_URL + "/private/user/role/" + oldUser.id + "?role=" + user.role_id,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        )
      } catch (error) {
        message.open({
          type: 'error',
          content: error.response.data.detail,
          duration: 3
        });
        return
      }
    }

    //department
    if (user.department_id !== oldUser.department_id && user.department_id) {
      try {
        await axios.patch(SERVER_URL + "/private/user/department/" + oldUser.id + "?department_id=" + user.department_id,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        )
      } catch (error) {
        message.open({
          type: 'error',
          content: error.response.data.detail,
          duration: 3
        });
        return
      }
    }

    // pop up success message
    message.open({
      type: 'success',
      content: '儲存成功',
      duration: 3
    });

    // update user
    setUser(prevState => ({
      ...prevState,
      ...user
    }));

    // update oldUser
    setOldUser(prevState => ({
      ...prevState,
      ...user
    }));

    // detectDifferent(false)
  }

  // TODO: update
  const handleReturn = () => {
    window.location.reload();
  }

  if (loading) {
    return (
      <div className='flex justify-center items-center w-full h-full'>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    )
  }

  return (
    <div className='flex flex-col w-full bg-white'>
      <div className="px-4 font-bold text-xl mb-1 mt-6">
        編輯帳號
      </div>
      <div className='w-[540px]'>
        <MgntAvatar
          photoUrl={user.photo_url ? user.photo_url : ''}
          uploadUrl={''}
          title=''
          name={oldUser.nickname}
        />
      </div>
      <div className='flex flex-col md:flex-row p-3 '>
        <div className='flex flex-col w-full md:w-1/2 '>
          <div className='flex w-[100%] p-1 '>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              暱稱</p>
            {/*
              <Input placeholder='暱稱' value={user.nickname} onChange={(e) => {
                setUser(prevState => ({
                  ...prevState,
                  nickname: e.target.value
                }));
              }} className='w-[240px] border-gray border-[1px] rounded-[5px]'></Input>
              */}
            <p className='w-[240px] h-[32px] p-1 bg-bgDark overflow-auto'>
              {user.nickname}
            </p>
          </div>
          <div className='flex w-[100%]  p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              啟用帳號</p>
            <Checkbox
              checked={user.enabled}
              onChange={(e) => {
                setUser(
                  prevState => ({
                    ...prevState,
                    enabled: e.target.checked
                  }));
              }} />
          </div>
          <div className='flex w-[100%] p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              電子信箱</p>
            <p className='w-[240px] h-[32px] p-1 bg-bgDark overflow-auto'>
              {oldUser.email}
            </p>
          </div>
          <div className='flex w-[100%] p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              可擁有房間數
            </p>
            <InputNumber
              placeholder='可擁有房間數'
              min={oldUser.created_rooms.length}
              max={departments && departments.find(d => d.id === user.department_id)?.chatroom_limit - departments.find(d => d.id === user.department_id)?.num_of_rooms}
              value={user.chatroom_limit}
              onChange={(e) => {
                setUser(prevState => ({
                  ...prevState,
                  chatroom_limit: e
                }));
              }} className='w-[240px] border-gray border-[1px] rounded-[5px]'></InputNumber>
          </div>
          <div className='flex w-[100%] p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              未使用房間數</p>
            <p className='w-[240px] h-[32px] p-1 bg-bgDark mr-[9px]'>
              {user.chatroom_limit - user.created_rooms.length}
            </p>
            {/* <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center'>
                調整</button> */}
          </div>
          <div className='flex w-[100%] p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              已創房間數</p>
            <p className='w-[240px] h-[32px] p-1 bg-bgDark mr-[9px]'>
              {user.created_rooms.length}
            </p>
            {/* <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center'>
                查看</button> */}
          </div>
        </div>
        <div className='flex flex-col w-full md:w-1/2'>
          <div className='flex w-[100%] p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              部門
            </p>
            {userRoleId === '4' ?
              <div className="flex flex-col">
                <Select
                  showSearch
                  placeholder="選擇部門"
                  value={user.department_id}
                  className="w-[240px] h-[32px]"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={departments.map(department => ({
                    value: department.id,
                    label: department.name,
                  }))}
                  onChange={(value) => {
                    setUser(prevState => ({
                      ...prevState,
                      department_id: value
                    }));
                  }}
                />
                {/* <div className="text-xs text-textDark self-end">
                  本部門剩餘可分配房間數：
                  {departments.find(d => d.id === user.department_id)?.chatroom_limit - departments.find(d => d.id === user.department_id)?.num_of_rooms - user.chatroom_limit}
                </div> */}
              </div>
              :
              <p className='w-[240px] h-[32px] p-1 bg-bgDark mr-[9px]'>
                {user.department_name}
              </p>
            }
          </div>
          <div className='flex w-[100%] p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              帳號到期日</p>
            <p type='date' className='w-[240px] h-[32px] p-1 bg-bgDark'>
              {user.expired_at}
            </p>
          </div>
          <div className='flex w-[100%] p-1'>
            <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
              權限</p>
            {
              userRoleId > oldUser.role_id ?
                <Select
                  showSearch
                  placeholder="選擇部門"
                  defaultValue={oldUser.role_name}
                  className="w-[240px] h-[32px]"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={ROLES.filter(role => role.value < parseInt(userRoleId))}
                  onChange={(value) => {
                    setUser(prevState => ({
                      ...prevState,
                      role_id: value
                    }));
                  }}
                />
                :
                <p className='w-[240px] h-[32px] p-1 bg-bgDark mr-[9px]'>
                  {user.role_name}
                </p>
            }
          </div>
        </div>
      </div>
      <div className='flex p-5 gap-2'>
        <Button onClick={handleReturn} danger>
          退出
        </Button>
        <Button onClick={handleSave} type="primary">
          儲存
        </Button>
      </div>
    </div>
  )
}

export default AcMgnt;