import React, { useState, useEffect, useRef } from 'react'
import { Input, Tooltip, Button, Select, message, Upload } from 'antd'
import { CloudUploadOutlined, CloseOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useAuth } from '@/contexts/AuthProvider'
import MemoryModal from './MemoryModal'

import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const { Dragger } = Upload;
const { TextArea } = Input;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Memory = ({ room }) => {
  const { token } = useAuth()
  const [text, setText] = useState("")
  const [url, setUrl] = useState("")
  const [blobs, setBlobs] = useState([])
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [memory, setMemory] = useState([])
  const [depth, setDepth] = useState(1)
  const [fetching, setFetching] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalUrl, setModalUrl] = useState("")
  const [currentRecordId, setCurrentRecordId] = useState(null)

  useEffect(() => {
    reset()
    handleFetchMemory()
  }, [room])

  const reset = () => {
    setText("")
    setUrl("")
    setBlobs([])
    setDepth(1)
  }

  const handleClickUploadMore = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setBlobs([...blobs, ...Array.from(e.target.files)]);
  };

  const removeFile = (index) => {
    setBlobs(blobs.filter((_, i) => i !== index));
  };

  const props = {
    name: 'file',
    multiple: true,
    onChange: (e) => {
      setBlobs([...blobs, ...e.fileList.map(file => file.originFileObj)]);
    },
    onDrop: (e) => {
      setBlobs([...blobs, ...Array.from(e.dataTransfer.files)]);
    },
  };

  const handleFetchMemory = async () => {
    setFetching(true); // Assuming setFetching updates a state to show loading status
    try {
      const response = await axios.get(`${SERVER_URL}/private/chatrooms/vector_store/${room.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Use Promise.all to handle multiple fetches concurrently
      const fetchTextFiles = response.data.map(async (item) => {
        if (item.blob && item.blob.content_type.includes('text/plain')) {
          try {
            const textResponse = await axios.get(item.blob.url)
            const content = typeof textResponse.data === "string" ? textResponse.data : JSON.stringify(textResponse.data);
            const filename = content.substring(0, 50)
            return { ...item, blob: { ...item.blob, content: content, filename: filename } }; // storing the fetched data in 'content'
          } catch (error) {
            // message.error(JSON.stringify(error));
            console.log(error);
            return item; // return the item unchanged if there was an error
          }
        }
        return item;
      });

      const updatedData = await Promise.all(fetchTextFiles);
      setMemory(updatedData); // Assuming setMemory updates the state with the new data
    } catch (error) {
      message.error(JSON.stringify(error));
    }
    setFetching(false); // Ensure fetching is set to false after operations complete
  };

  const handlePostText = async () => {
    try {
      await axios.post(SERVER_URL + '/private/chatrooms/vector_store/text/' + room.id, {
        text: text,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  }

  const handlePostUrl = async () => {
    try {
      await axios.post(SERVER_URL + '/private/chatrooms/vector_store/url/' + room.id, {
        url: url,
        depth: depth
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  }

  const handlePostFiles = async () => {
    try {
      const formData = new FormData();
      blobs.forEach(blob => {
        formData.append('files', blob);
      });

      await axios.post(SERVER_URL + '/private/chatrooms/vector_store/file/' + room.id, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  }

  const handleMemorize = async () => {
    setUploading(true)
    if (text.length > 0) {
      await handlePostText()
    }
    if (url.length > 0) {
      await handlePostUrl()
    }
    if (blobs.length > 0) {
      await handlePostFiles()
    }
    reset()
    handleFetchMemory()
    setUploading(false)
    message.open({
      type: 'success',
      content: '記憶成功送出！若檔案類型支援，稍後重整即可在右側查看',
      duration: 5
    });
  }

  const deleteMemory = async (recordId) => {
    setFetching(true)
    try {
      await axios.delete(SERVER_URL + '/private/chatrooms/vector_store/' + room.id + '?record_id=' + recordId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error));
    }
    handleFetchMemory()
    setFetching(false)
  }

  const handleOpenModal = (type, url, id) => {
    setCurrentRecordId(id)
    if (type === 'url') {
      window.open(url, '_blank')
      return
    }
    if (type.includes('text/plain')) {
      setModalUrl(url)
      setOpenModal(true)
      return
    }
    window.open(url, '_blank')
    return
  }

  const formatFileName = (item) => {
    if (item.blob.content_type === "webpage") {
      return item.blob.url
    }
    if (item.blob.filename) {
      return item.blob.filename
    }
    return item.blob.id
  }

  const formatFileFormat = (type) => {
    if (type === 'webpage') return "網頁存擋"
    if (type.includes('text/plain')) return "文字檔"
    return "檔案"
  }

  const formatFileSize = (size) => {
    if (size < 1024) return size + ' bytes';
    else if (size >= 1024 && size < 1048576) return (size / 1024).toFixed(1) + ' KB';
    else return (size / 1048576).toFixed(1) + ' MB';
  };

  return (
    <>
      <MemoryModal token={token} recordId={currentRecordId} roomId={room.id} url={modalUrl} open={openModal} onCancel={() => setOpenModal(false)} />
      <div className="flex flex-row h-full overflow-scroll" style={{ width: 'calc(100vw - 620px)' }}>
        <div className="flex flex-col w-1/2 h-full items-center justify-start border-r border-r-borderLight p-4 gap-4">
          <h1 className="text-lg font-bold text-textDark self-start">新增記憶</h1>
          <TextArea disabled={uploading} placeholder="輸入要記憶的文字內容直接建立檔案" style={{ height: 320, resize: 'none' }} value={text} onChange={(e) => setText(e.target.value)} maxLength={60000} showCount className="mb-2" />
          {
            blobs.length > 0 ?
              (
                <div className="flex flex-col gap-1 max-h-[260px] overflow-scroll min-h-[260px] w-full">
                  {blobs.map((blob, index) => (
                    <div key={index} className="flex flex-row gap-1 w-full min-h-11 items-center border border-borderLight px-4 py-2 rounded justify-between">
                      <div className="w-full truncate">
                        {blob.name}
                      </div>
                      <div className="min-w-fit text-textLight">
                        {formatFileSize(blob.size)}
                        <CloseOutlined disabled={uploading} onClick={() => removeFile(index)} className="text-textDark hover:text-red-500 cursor-pointer ml-2" />
                      </div>
                    </div>
                  ))}
                  <Tooltip title="目前僅支援 .txt, .pdf, .docs, .docx, .ppt, .pptx, .csv, .xlsx, .json, .odt, .epub, .tsv 以及 .md，上傳不支援檔案將不會顯示於右側">
                    <div>
                      <input type="file" multiple onChange={handleFileChange} id="file-upload" style={{ display: 'none' }} ref={fileInputRef} />
                      <Button disabled={uploading} type="text" className="min-h-11 w-full" icon={<CloudUploadOutlined />} onClick={handleClickUploadMore}>新增更多檔案</Button>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <Tooltip title="目前僅支援 .txt, .pdf, .docs, .docx, .ppt, .pptx, .csv, .xlsx, .json, .odt, .epub, .tsv 以及 .md，上傳不支援檔案將不會顯示於右側">
                  <Dragger disabled={uploading} {...props} className="min-h-[260px] w-full">
                    <p className="text-textLight text-[80px]">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">點擊選擇要記憶的檔案</p>
                  </Dragger>
                </Tooltip>
              )}
          <div className="flex flex-row w-full gap-1">
            <Tooltip title="網址">
              <Input disabled={uploading} placeholder="輸入要記憶的網址" value={url} onChange={(e) => setUrl(e.target.value)} />
            </Tooltip>
            <Tooltip title="深度">
              <Input disabled={uploading} placeholder="網址深度" value={depth} onChange={(e) => setDepth(e.target.value)} className="w-32" />
            </Tooltip>
          </div>
          <div className="flex flex-row gap-2 self-end mt-8">
            <Button disabled={uploading} onClick={reset}>清除</Button>
            <Button type="primary" onClick={handleMemorize} loading={uploading} disabled={text.length === 0 && blobs.length === 0 && (url.length === 0 || !depth)}>記憶</Button>
          </div>
        </div>
        <div className="flex flex-col w-1/2 min-h-full h-fit items-center justify-start border-l border-l-borderLight p-4 gap-4 overflow-visible pb-8">
          <h1 className="text-lg font-bold text-textDark self-start">
            目前記憶
            <ReloadOutlined
              className="hover:text-primary ml-2"
              style={{ width: "12px", height: "12px" }}
              onClick={handleFetchMemory}
            />
          </h1>
          <div className="flex flex-col w-full">
            <div className="flex flex-row bg-bgDark h-8 items-center w-full gap-2 px-2">
              <div className="flex w-[600px] text-sm">記憶內容</div>
              <div className="flex min-w-20 text-sm">建立時間</div>
              <div className="flex min-w-12 text-sm">類型</div>
              <div className="flex min-w-4 bg-bgDark h-full ml-2" />
            </div>
            {
              fetching ?
                <div className="flex justify-center items-center w-full h-72">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                </div>
                :
                memory.map((item, index) => (
                  <div className="flex flex-row border-b border-b-borderLight h-11 items-center w-full gap-2 px-2">
                    <div className="flex w-[600px] cursor-pointer hover:underline truncate" onClick={() => handleOpenModal(item.blob.content_type, item.blob.url, item.id)}>
                      {formatFileName(item)}
                    </div>
                    <div className="flex min-w-20 text-xs">{item.blob.created_at.split(' ')[0]}</div>
                    <div className="flex min-w-12 truncate text-xs">{formatFileFormat(item.blob.content_type)}</div>
                    <CloseOutlined className="text-textDark hover:text-red-500 cursor-pointer ml-2" onClick={() => deleteMemory(item.id)} />
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Memory