// Qa component
export const Qa = ({ handleButtonClick, selectedButton }) => {
    return (
      <>
        <div className="flex flex-row w-full h-full p-1">
          <div className="flex flex-col w-1/2 h-full justify-start border-r border-r-borderLight p-3 gap-4">
            {/* nav btn */}
            <div className="flex justify-between bg-gray-100 w-[276px] rounded">
              <button
                onClick={() => handleButtonClick("general")}
                className={`px-2 py-1 flex-1 rounded ${selectedButton === "general" ? "bg-[#ADDEAC]" : "bg-gray-100"
                  }`}
              >
                一般記憶
              </button>
              <button
                onClick={() => handleButtonClick("advanced")}
                className={`px-2 py-1 flex-1 rounded ${selectedButton === "advanced" ? "bg-[#ADDEAC]" : "bg-gray-100"
                  }`}
              >
                進階記憶
              </button>
              <button
                onClick={() => handleButtonClick("qa")}
                className={`px-2 py-1 flex-1 rounded ${selectedButton === "qa" ? "bg-[#ADDEAC]" : "bg-gray-100"
                  }`}
              >
                問答記憶
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };