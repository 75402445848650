import React, { useState, useEffect } from 'react'
import axios from 'axios'
import mqtt from 'mqtt'

import { useAuth } from '@/contexts/AuthProvider'
import { message } from 'antd'

import BoundList from './BoundList'
import BoundChatRoom from './BoundChatRoom'
import BoundSetting from './BoundSetting'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const MQTT_URL = "wss://api-broker.scfg.io" // process.env.REACT_APP_MQTT_URL;
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

const tabList = [
  {
    key: 0,
    label: 'Airbnb',
  },
  {
    key: 1,
    label: 'Line',
  },
  // {
  //   key: 2,
  //   label: '語音',
  // },
];

const BoundInterface = ({ room, switchTo }) => {
  const initialClientsAirbnb = JSON.parse(localStorage.getItem('clientsAirbnb')) || [];

  const { userId, token } = useAuth()
  const [clients, setClients] = useState([])
  const [clientsAirbnb, setClientsAirbnb] = useState([])
  const [channelType, setChannelType] = useState('line')
  const [chatType, setChatType] = useState('ai')
  const [activeId, setActiveId] = useState('')
  const [loading, setLoading] = useState(false)
  const [tabs, setTabs] = useState(0)
  const [mqttConnection, setMqttConnection] = useState(false)
  const [activeClient, setActiveClient] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [newMessageClientList, setNewMessageClientList] = useState([])
  const [requestQueue, setRequestQueue] = useState([]);
  const [messageId, setMessageId] = useState(null);

  const roomId = room.id
  useEffect(() => {
    setMqttConnection(false)
    fetchClients(true)
    fetchAirbnbClients(true)
    setActiveId('')
    setActiveClient(null)
    setNewMessageClientList([])
  }, [room])

  useEffect(() => {
    const sourceClients = tabs === 0 ? clientsAirbnb : clients;
    setActiveClient(sourceClients.find(client => client.userId === activeId));
    setNewMessageClientList(prevList => prevList.filter(clientId => clientId !== activeId));
  }, [activeId])

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: 'mqttx_' + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: '/',
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on('error', (error) => {
      // console.error('Connection error:', error);
      setMqttConnection(false)
    });

    mqttClient.on('connect', () => {
      mqttClient.subscribe(`chatroom/${room.id}/line/#`, (error) => {
        if (error) {
          // console.error('Subscription error:', error);
          setMqttConnection(false)
        } else {
          // console.log('Subscription successful');
          setMqttConnection(true)
        }
      });
    });

    mqttClient.on('message', (topic, payload) => {
      const receivedClientId = topic.split('/')[3].toString()
      // console.log('INTERFACE | Message received:', receivedClientId, "content", payload.toString());
      // console.log('INTERFACE | Active clients:', clients);

      // filter new client
      // const newClient = clients.find(client => client.userId === receivedClientId);
      // console.log('the new client', newClient)

      // unfocused client got message
      if (receivedClientId !== activeId) {
        setNewMessageClientList(prevList => [...prevList, receivedClientId])
      }

      // new client or message
      fetchClients();
      fetchAirbnbClients();
    });

    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [clients, activeId]);

  const fetchClients = async (init = false) => {
    if (init) {
      setLoading(true)
    }
    // TODO fetch different platform based on tab key value
    try {
      const response = await axios.get(`${SERVER_URL}/private/property/chatroom/line_channel/${room.id}/clients`, {
        params: {
          channel_type: `${channelType}`,
          chat_type: `${chatType}`
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      // check if there's a null property in one element of the response.data.valid
      if (response.data.valid.length > 0 && response.data.valid[0].displayName === null) {
        message.error('Line@ 帳號可能已經串接至其他聊天室，請至 Line@ 後台檢查。')
        return
      }
      setClients(response.data.valid)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const processOneAirbnbMessage = (rawData, mes) => {
    setMessageId(mes);
    return rawData.result
      ? rawData.result.map((item) => {
        if (!item.result || item.result.length === 0) {
          return null;
        }

        const senderWithReservation = item.result.find(
          (message) => message.sender && message.sender.includes('預訂人')
        );

        const displayName = senderWithReservation ? senderWithReservation.sender : ' · 預訂人';
        const pictureUrl = senderWithReservation ? senderWithReservation.sender_photo_url : null;

        let lastMessage = null;
        for (let i = item.result.length - 1; i >= 0; i--) {
          if (item.result[i].content) {
            lastMessage = item.result[i];
            break;
          }
        }

        if (!lastMessage) {
          lastMessage = {
            content: 'No messages',
            date: '',
            time: ''
          };
        }

        return {
          id: item.last_update_timestamp,
          userId: item.last_update_timestamp ? item.last_update_timestamp : '· 預訂人',
          messageId: mes,
          roomId: roomId,
          displayName: displayName,
          pictureUrl: pictureUrl,
          channel: 'airbnb',
          last_two_messages: [
            {
              created_at: `${lastMessage.time}`,
              message: lastMessage.content,
            },
          ],
          unreadCount: 1

        };
      }).filter(item => item !== null)
      : [];
  };

  const reFresh = () => {
    if (fetching) {

    } else {
      fetchAirbnbClients(true);
    }
  };

  const fetchAirbnbClients = async (init = false) => {
    if (init) {
      setLoading(true)
      setFetching(true)
    }

    if (requestQueue.length > 0) {
      console.log("Another request is already in progress, adding this to the queue.");
      setRequestQueue(prevQueue => [...prevQueue, { roomId, token }]);
      return;
    }
    // TODO fetch different platform based on tab key value
    setTimeout(async () => {
      try {
        // 1. 首先嘗試 GET 請求來獲取 message IDs
        let idsResponse = await axios.get(`${SERVER_URL}/private/module/rpa/airbnb/message_ids/${roomId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // 2. 如果 GET 沒有返回資料，則發送 POST 請求來觸發資料生成
        if (!idsResponse || !idsResponse.data.result || idsResponse.data.result.length === 0) {
          console.warn("No message IDs returned, attempting to trigger task via POST.");

          try {
            // 發送 POST 請求
            await axios.post(`${SERVER_URL}/private/module/rpa/airbnb/message_ids/${roomId}`, {}, {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            });

            // 等待 2 秒後再發送 GET 請求
            await new Promise(resolve => setTimeout(resolve, 2000));

            // 重新發送 GET 請求
            idsResponse = await axios.get(`${SERVER_URL}/private/module/rpa/airbnb/message_ids/${roomId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            

            if (!idsResponse || !idsResponse.data.result || idsResponse.data.result.length === 0) {
              console.warn("No message IDs returned after POST.");
              setLoading(false);
              return; // 如果 GET 再次失敗，退出函數
            }
          } catch (postError) {
            console.error("Failed to trigger task via POST:", postError);
            setLoading(false);
            return; // 如果 POST 失敗，退出函數
          }
        }

        // 3. 有效 message IDs 返回後，進行處理
        const messageIds = idsResponse.data.result;
        let messages = [];

        for (let messageId of messageIds) {
          try {
            await new Promise(resolve => setTimeout(resolve, 2000)); // 延遲 2 秒
            // 首先嘗試 GET 請求來檢查該 message_id 是否已有資料
            const messageResponse = await axios.get(
              `https://api.scfg.io/private/module/rpa/airbnb/message/${roomId}`,
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
                params: {
                  message_id: messageId
                }
              }
            );

            if (messageResponse.data && messageResponse.data.result && messageResponse.data.result.length > 0) {
              // 如果 GET 已有資料，直接處理
              messages.push(messageResponse.data);
            } else {
              // 如果 GET 沒有資料，則發送 POST 請求，然後重新發送 GET
              await axios.post(
                `https://api.scfg.io/private/module/rpa/airbnb/message/${roomId}`,
                {},
                {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  },
                  params: {
                    message_id: messageId,
                    clean_run: false
                  }
                }
              );

              await new Promise(resolve => setTimeout(resolve, 2000)); // 延遲 2 秒

              // 重新發送 GET 請求
              const retryMessageResponse = await axios.get(
                `https://api.scfg.io/private/module/rpa/airbnb/message/${roomId}`,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  },
                  params: {
                    message_id: messageId
                  }
                }
              );

              if (retryMessageResponse.data && retryMessageResponse.data.result) {
                messages.push(retryMessageResponse.data);
              }
            }

            // 處理消息
            const processedMessages = processOneAirbnbMessage({ result: messages }, messageId);

            setClientsAirbnb(prevClients => {
              // 確保 prevClients.airbnb 是陣列，保持其他屬性不變
              const prevAirbnbClients = Array.isArray(prevClients) ? prevClients : [];

              // 過濾掉已經存在的訊息
              const newMessages = processedMessages.filter(newMsg =>
                !prevAirbnbClients.some(existingMsg => existingMsg.messageId === newMsg.messageId)
              );

              if (newMessages.length === 0) return prevClients; // 沒有新訊息時，不做更新

              // 更新 airbnb 的訊息，並保持原本的狀態屬性
              const updatedClients = [
                ...prevAirbnbClients, // 保留原本的資料
                ...newMessages // 加入新的訊息
              ];

              // 將更新後的資料儲存在 localStorage 中
              localStorage.setItem('clientsAirbnb', JSON.stringify(updatedClients));

              return updatedClients; // 返回更新後的狀態
            });
            setLoading(false);


            messages = [];

          } catch (error) {
            // 處理單個 messageId 的錯誤，繼續處理其他的 messageId
            console.error(`Error processing messageId ${messageId}:`, error);
            continue; // 跳過當前 messageId，繼續下一個
          }
          
        }

        // 繼續處理隊列中的請求
        if (requestQueue.length > 0) {
          const nextRequest = requestQueue.shift();
          setRequestQueue([...requestQueue]); // 更新佇列
          fetchAirbnbClients(nextRequest.roomId, nextRequest.token);
        }

      } catch (error) {
        console.error("Error fetching Airbnb messages:", error);
      } finally {
        setLoading(false);
        setFetching(false)
      }
    }, 2000);
  }

  const onChangeTab = (key) => {
    setTabs(key);
    setChannelType(key === 0 ? 'airbnb' : 'line');
    fetchClients(); // Fetch new clients based on tab selection
    fetchAirbnbClients();

  };


  return (
    <div className="w-full flex flex-row">
      <BoundList
        clients={tabs === 0 ? clientsAirbnb : clients}
        activeId={activeId}
        setActiveId={setActiveId}
        loading={loading}
        tabs={tabList}
        onChangeTab={onChangeTab}
        fetchClients={fetchClients}
        fetchAirbnbClients={fetchAirbnbClients}
        switchTo={switchTo}
        mqttConnection={mqttConnection}
        newMessageClientList={newMessageClientList}
        reFresh={reFresh}
      />
      {
        activeClient ?
          <>

            <BoundChatRoom userId={userId} token={token} room={room} client={activeClient} tabs={tabs}/>
            <BoundSetting room={room} token={token} clientId={activeClient.userId} tabs={tabs} roomId={room.id} messageId={messageId}/>

          </>
          :
          <div className="flex flex-row items-center justify-center w-full h-full text-2xl font-bold text-textLight">
            請選擇第三方紀錄
          </div>
      }
    </div>
  )
}

export default BoundInterface