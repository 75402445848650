import React from 'react'
import classnames from 'classnames'

import userAvatar from '../../assets/userAvatar.png'
import { useBound } from '@/contexts/BoundContext'
const OFFSET_HOUR = 8;

const BoundListItem = ({ client, activeId, setActiveId, newMessage = false }) => {
  const { roomId, setRoomId, messageId, setMessageId } = useBound();
  const hasFakeMessage = client.last_two_messages[0].response && client.last_two_messages[0].response.message === '#.aFaKeMeSsAgE';

  const formatAndTimeZone = (timestamp) => {
    if (timestamp === null) { return "..."; }

    let timeObj = new Date(timestamp);
    let today = new Date();

    timeObj.setHours(timeObj.getHours() + OFFSET_HOUR); // TODO: why no *2?

    const isToday = timeObj.toDateString() === today.toDateString();
    // console.log(timeObj, today.toDateString(), isToday);

    const month = ('0' + (timeObj.getMonth() + 1)).slice(-2);
    const day = ('0' + timeObj.getDate()).slice(-2);
    const hour = ('0' + timeObj.getHours()).slice(-2);
    const minute = ('0' + timeObj.getMinutes()).slice(-2);

    if (isToday) {
      return `${hour}:${minute}`;
    } else {
      return `${month}/${day}`;
    }
  };

  return (
    <div
      className={
        classnames(
          "flex flex-row gap-2 w-full h-full cursor-pointer px-2 py-2 items-center rounded bg-bgLight",
          { "hover:bg-bgDark": activeId !== client.userId },
          { "bg-primaryMedium hover:bg-primaryMedium": activeId === client.userId })}
      onClick={() => {
        setActiveId(client.userId)
        setRoomId(client.roomId)
        setMessageId(client.messageId)
      }}
    >
      <img src={client.pictureUrl ? client.pictureUrl : userAvatar} alt={client.displayName} className="w-12 h-12 rounded-full" />
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row w-full h-full gap-1 justify-between items-center line-clamp-1 truncate">
          {client.displayName}
          <div className="text-xs text-textLight">
            {
              hasFakeMessage || !client.last_two_messages[0].response ?
                client.last_two_messages[0].created_at :
                client.last_two_messages[0].response ? client.last_two_messages[0].response.created_at : null
            }
          </div>
        </div>
        <div className="flex flex-row h-full w-full items-center gap-1 justify-between">
          <div className="text-xs text-textDark line-clamp-1 w-[188px]">
            {
              hasFakeMessage || !client.last_two_messages[0].response ?
                (client.last_two_messages[0].message ? client.last_two_messages[0].message : "...") :
                (client.last_two_messages[0].response ? client.last_two_messages[0].response.message : "...")
            }
          </div>
          {
            newMessage &&
            <div className="bg-red-600 rounded-full w-2 h-2 my-auto" />
          }
          {
            client.unreadCount && client.unreadCount > 0 ?
              (client.unreadCount > 999 ?
                <div className="text-xs text-white bg-primary font-bold p-1 rounded max-w-10">{"999+"}</div>
                :
                <div className="text-xs text-white bg-primary font-bold p-1 rounded max-w-10">{client.unreadCount}</div>)
              :
              null
          }
        </div>
      </div>
    </div>
  )
}

export default BoundListItem