import React from 'react'
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ExploreIcon } from './assets/explore_tab_icon.svg';
import { ReactComponent as ChatIcon } from './assets/chat_tab_icon.svg';
import { ReactComponent as MultiPostIcon } from './assets/multipost_tab_icon.svg';
import { ReactComponent as VoiceIcon } from './assets/voice_tab_icon.svg';
import Logo from '../logo/Logo';

const NavBar = () => {
  const location = useLocation();

  return (
    <div className="flex flex-col items-center justify-start h-screen min-w-24 w-24 pt-4 gap-4 bg-bgDark">
      <a href="/" className='w-[45px] h-[45px] flex justify-center items-center overflow-hidden' >
        <Logo type="NAVBAR" />
      </a>
      <a href="/explore" className={classNames({
        'border-primary': location.pathname.includes("/explore"),
        'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <ExploreIcon />
      </a>

      <a href="/chat" className={classNames({
        'border-primary': location.pathname.includes("/chat"),
        'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <ChatIcon />
      </a>

      {/* <a href="/multipost" className={classNames({
        'border-primary': location.pathname.includes("/multipost"),
        'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <MultiPostIcon />
      </a> */}

      {/* <a href="/voice" className={classNames({
        'border-primary': location.pathname.includes("/voice"),
        'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <VoiceIcon />
      </a> */}

      <div
        className="text-xs text-bgMedium"
      >
        v-1009-00
      </div>

    </div>
  )
}

export default NavBar