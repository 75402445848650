import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import './App.css';

import { useAuth } from '@/contexts/AuthProvider';

import Redirection from '@/app/landing/Redirection';
import Login from '@/app/landing/Login';
import Register from '@/app/landing/Register';
import Forget from '@/app/landing/Forget';
import Reset from '@/app/landing/Reset';
import Explore from '@/app/explore/Explore';
import Chat from '@/app/chat/Chat';
import Settings from '@/app/systemSettings/Settings';
import NavBar from '@/components/navBar/NavBar';

const teamsyncTheme = {
  token: {
    colorPrimary: '#67be5f',
    colorSecondary: '#e0e0e0',
    colorBgContainer: '#f7f7f7',
  },
};

function App() {
  const { token } = useAuth();

  return (
    <ConfigProvider theme={teamsyncTheme}>
      <Router>
        <div className="flex flex-row overflow-x-hidden">
          {token && <NavBar />}
          <Routes>
            <Route path="/login" element={token ? <Redirection target={"/explore"} /> : <Login />} />
            <Route path="/register" element={token ? <Redirection target={"/explore"} /> : <Register />} />
            <Route path="/forget" element={token ? <Redirection target={"/explore"} /> : <Forget />} />
            <Route path="/reset/:ticketId" element={token ? <Redirection target={"/explore"} /> : <Reset />} />

            <Route path="/" element={token ? <Redirection target={"/explore"} /> : <Login />} />
            <Route path="/explore" element={token ? <Explore /> : <Login />} />
            <Route path="/chat/:roomId?" element={token ? <Chat /> : <Login />} />
            <Route path="/settings" element={token ? <Settings /> : <Login />} />

            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </div>
      </Router>
    </ConfigProvider>
  );
}

export default App;