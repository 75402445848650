import React, { useState } from 'react'
import axios from 'axios';
import { Form, Input, Button, notification } from "antd";
import { useParams } from 'react-router-dom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Reset = () => {
  let { ticketId } = useParams();
  const [password, setPassword] = useState("")
  const [passwordCheck, setPasswordCheck] = useState("")
  const [loading, setLoading] = useState(false);

  const openNotification = (title, description) => {
    notification.open({
      message: title,
      description: description,
      placement: "bottomRight",
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    // check same
    if (password !== passwordCheck) {
      openNotification("密碼不一致", "請確認密碼是否相同")
      setLoading(false);
      return
    }

    // check length
    if (password.length < 10) {
      openNotification("密碼長度不足", "請輸入長度大於 10 的密碼")
      setLoading(false);
      return
    }

    // Validate the password
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d._!#-]{10,}$/.test(password)) {
      openNotification("密碼格式錯誤", "特殊符號支援 . _ ! # - 五種。");
      setLoading(false);
      return;
    }

    // check having both upper and lower case letters and numbers
    if (!/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/[0-9]/.test(password)) {
      openNotification("密碼格式錯誤", "請確認密碼包含至少一個大寫字母、一個小寫字母、一個數字")
      setLoading(false);
      return
    }

    try {
      await axios.patch(SERVER_URL + "/public/auth/password/" + ticketId, {
        password: password,
      })
    } catch (error) {
      openNotification("密碼重設失敗", "請洽管理員");
      setLoading(false);
      return
    }

    openNotification("密碼已重設", "請使用新密碼登入");
    setLoading(false);
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="flex flex-col items-center justify-center w-80 gap-4">
        <a href="/login" className="flex flex-row juystify-start w-full hover:underline cursor-pointer">
          返回
        </a>
        <div className="text-2xl font-bold">
          重設密碼
        </div>
        <div className="text-xs text-textLight text-center">
          密碼需包含至少一個大寫字母、一個小寫字母、一個數字，且長度需大於 10 個字元，特殊符號僅支援 . _ ! # - 五種。
        </div>
        <Form
          layout="vertical"
          style={{
            width: "320px",
          }}
        >
          <Form.Item label="密碼">
            <Input.Password onChange={e => setPassword(e.target.value)} disabled={loading} />
          </Form.Item>
          <Form.Item label="再次輸入密碼">
            <Input.Password onChange={e => setPasswordCheck(e.target.value)} disabled={loading} />
          </Form.Item>

          <Form.Item className="flex flex-row justify-end w-full">
            <Button onClick={handleSubmit} disabled={!password || !passwordCheck} loading={loading} className="mt-2">重設密碼</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Reset