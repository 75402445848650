import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const BoundContext = createContext();

export const BoundProvider = ({ children }) => {
  const [ roomId , setRoomId ] = useState('');
  const [ messageId, setMessageId ] = useState('');



  return (
    <BoundContext.Provider value={{roomId, setRoomId, messageId, setMessageId }}>
      {children}
    </BoundContext.Provider>
  );
};

// hook
export const useBound = () => {
    return useContext(BoundContext);
  };
  