import React, { useEffect, useState } from "react";
import { Input, Button, Row, Col, Space, Card, Spin, message } from "antd";
import { useDispatch } from 'react-redux';
import { addTemplate, updateTemplate } from '@/service/memoryService';
import { useAuth } from '@/contexts/AuthProvider';

import CloseCircleOutlinedImg from "../assets/CloseCircleOutlined-icon.png";
import PlusCircleOutlinedImg from "../assets/PlusCircleOutlined-icon.png";
import DoubleRightOutlinedImg from "../assets/DoubleRightOutlined-icon.png";
import ActiveRightArrowImg from "../assets/ActiveRightArrow-icon.png";
import { LoadingOutlined } from "@ant-design/icons";

// level 1, 2, 3 block
const LevelSection = ({
  level,
  items,
  onAdd,
  onChange,
  onDelete,
  onNextLevel,
  selectedItem,
  setSelectedItem,
  show = true,
  previousLevelHasValue = true,
}) => {
  return (
    <Card
      bordered={false}
      style={{
        marginBottom: 20,
        height: "100%",
        backgroundColor: "white",
        border: "1px solid #E0E0E0",
        borderRadius: "0",
        margin: "0",
      }}
    >
      <div className="text-[14px] mb-5">Level {level}</div>
      {show && Array.isArray(items) && items.map((item, index) => (
        <Row
          key={`level-${level}-item-${index}`}
          gutter={16}
          align="middle"
          style={{ marginBottom: 10 }}
        >
          <Col span={16}>
            <Input
              placeholder={`Level ${level} 標籤`}
              value={item.name}
              onChange={(e) => onChange(level, index, e.target.value)}
            />
          </Col>
          <Col span={8}>
            <Space className="flex justify-end">
              <Button
                onClick={() => onDelete(level, index)}
                className="bg-transparent border-none p-0 hover:opacity-75"
              >
                <img src={CloseCircleOutlinedImg} alt="Delete" className="w-4 h-4" />
              </Button>
              {level < 3 && (
                <Button
                  onClick={() => {
                    onNextLevel(level, index);
                    setSelectedItem(index);
                  }}
                  className={`bg-transparent border-none p-0 hover:bg-green-600`}
                >
                  {selectedItem === index ? <img src={ActiveRightArrowImg} alt="Next Level" className="w-4 h-4" /> : <img src={DoubleRightOutlinedImg} alt="Next Level" className="w-4 h-4" />}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      ))}
      {show && previousLevelHasValue && (
        <Button
          style={{
            color: "#67BE5F",
            backgroundColor: "white",
            width: "188px",
            border: "1px dashed #67BE5F",
          }}
          type="dashed"
          onClick={() => {
            onAdd(level);
          }}
          block
          icon={<img src={PlusCircleOutlinedImg} alt="Add" style={{ width: 16, height: 16 }} />}
        >
          新增 Level {level} 標籤
        </Button>
      )}
    </Card>
  );
};

// TemplateEditor component
const TemplateEditor = ({
  setShowTemplateEditor,
  handleButtonClick,
  selectedButton,
  templateData = {},
  templateIndex,
  setSelectedTemplate,
  room
}) => {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const [templateName, setTemplateName] = useState(""); // edit templateData's name
  const [templateValue, setTemplateValue] = useState(""); // edit templateData's value
  const [structure, setStructure] = useState([]); // structure state
  const [loading, setLoading] = useState(false); // loading state
  const [selectedPath, setSelectedPath] = useState([]); // selected path state
  const [selectedItems, setSelectedItems] = useState({ // selected items state
    1: null,
    2: null,
    3: null,
  });
  // show levels state
  const [showLevels, setShowLevels] = useState({
    1: true,
    2: false,
    3: false,
  });

  // ensure children for each level
  const ensureChildren = (data, level = 1) => {
    if (!Array.isArray(data)) return [];
    return data.map(item => {
      const newItem = { ...item };
      if (level < 3 && !Array.isArray(newItem.children)) {
        newItem.children = [];
      }
      if (newItem.children && Array.isArray(newItem.children)) {
        newItem.children = ensureChildren(newItem.children, level + 1);
      }
      return newItem;
    });
  };

  // 判斷並預先渲染下級層級
  const findFirstItemWithChildren = (items, level) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (Array.isArray(item.children) && item.children.length > 0) {
        return { item, index: i };
      }
    }
    return null;
  };

  const preRenderNextLevel = (structure) => {
    const level1Result = findFirstItemWithChildren(structure, 1);
    if (level1Result) {
      setShowLevels((prev) => ({ ...prev, 2: true }));
      setSelectedItems((prev) => ({ ...prev, 1: level1Result.index }));
      setSelectedPath([level1Result.index]);

      const level2Result = findFirstItemWithChildren(level1Result.item.children, 2);
      if (level2Result) {
        setShowLevels((prev) => ({ ...prev, 3: true }));
        setSelectedItems((prev) => ({ ...prev, 2: level2Result.index }));
        setSelectedPath([level1Result.index, level2Result.index]);
      }
    }
  };

  // initialize templateData to page
  useEffect(() => {
    if (templateData && templateData.structure && Array.isArray(templateData.structure.structure)) {
      setTemplateName(templateData.name || "");
      setTemplateValue(templateData.value || "");
      const structuredData = ensureChildren(JSON.parse(JSON.stringify(templateData.structure.structure)));
      setStructure(structuredData);
      setSelectedPath([]);
      setSelectedItems({ 1: null, 2: null, 3: null });
      setShowLevels({ 1: true, 2: false, 3: false });

      // 預先渲染下一級層級
      preRenderNextLevel(structuredData);
    } else {
      setTemplateName("");
      setTemplateValue("");
      setStructure([]);
      setSelectedPath([]);
      setSelectedItems({ 1: null, 2: null, 3: null });
      setShowLevels({ 1: true, 2: false, 3: false });
    }
  }, [templateData]);

  // handle set selected item
  const handleSetSelectedItem = (level, index) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelected = { ...prevSelectedItems, [level]: index };
      if (level === 1) {
        newSelected[2] = null;
        newSelected[3] = null;
      } else if (level === 2) {
        newSelected[3] = null;
      }
      return newSelected;
    });

    setSelectedPath((prevPath) => {
      const newPath = [...prevPath];
      newPath[level - 1] = index;
      return newPath.slice(0, level);
    });
  };

  // get item by path
  const getItemByPath = (path, data) => {
    let current = data;
    for (let i = 0; i < path.length; i++) {
      const index = path[i];
      if (!Array.isArray(current)) {
        return [];
      }
      current = current[index]?.children || [];
    }
    return Array.isArray(current) ? current : [];
  };

  // handle level change
  const handleLevelChange = (level, index, value) => {
    const newData = [...structure];

    const path = selectedPath.slice(0, level - 1).concat(index);
    const item = getItemByPath(path.slice(0, -1), newData)[
      path[path.length - 1]
    ];
    if (item) {
      item.name = value;
      setStructure(newData);
    }
  };

  // handle delete item
  const handleDeleteItem = (level, index) => {
    const newData = [...structure];
    const parent = getItemByPath(selectedPath.slice(0, level - 1), newData);
    if (parent && Array.isArray(parent)) {
      parent.splice(index, 1);
      setStructure(newData);
      if (selectedItems[level] === index) {
        handleSetSelectedItem(level, null);
      }
    }
  };

  // handle add item
  const handleAddItem = (level) => {
    const newData = [...structure];
    const path = selectedPath.slice(0, level - 1);
    const parent = getItemByPath(path, newData);
    if (Array.isArray(parent)) {
      if (level < 3) {
        parent.push({
          name: "",
          children: [],
        });
      } else {
        parent.push({
          name: "",
        });
      }
      setStructure(newData);
    }
  };

  // handle next level
  const handleNextLevel = (level, index) => {
    if (level === 1) {
      setShowLevels((prevShowLevels) => ({
        1: true,
        2: false,
        3: false,
      }));
    }
    if (level < 3) {
      const newPath = selectedPath.slice(0, level - 1).concat(index);
      setSelectedPath(newPath);
      handleSetSelectedItem(level, index);
      setShowLevels((prevShowLevels) => ({
        ...prevShowLevels,
        [level + 1]: true,
      }));
    }
  };

  // map structure
  const mapStructure = (items) => {
    return items.map(item => {
      const newItem = { name: item.name };
      if (item.value !== null && item.value !== undefined) {
        newItem.value = item.value;
      }
      if (item.children && item.children.length > 0) {
        newItem.children = mapStructure(item.children);
      }
      return newItem;
    });
  };

  // handle save template
  const handleSaveTemplate = () => {
    if (!templateName || templateName.trim() === "") {
      message.warning('請輸入模板名稱');
      return;
    }
    setLoading(true);
    const temp = {
      name: templateName,
      value: templateValue || "",
      structure: mapStructure(structure),
    };
  
    if (templateIndex !== null && templateIndex !== undefined) {
      dispatch(updateTemplate({ room_id: room.id, template: temp, template_id: templateData.id }));
    } else {
      dispatch(addTemplate({ room_id:room.id, template:temp}));
    }
    setLoading(false);
    setShowTemplateEditor(false);
    setSelectedTemplate(null);
  };

  // check previous level has value
  const checkPreviousLevelHasValue = (level) => {
    if (level === 1) return true;
    const previousLevelItems =
      level === 2
        ? structure
        : getItemByPath(selectedPath.slice(0, 1), structure) || [];
    if (!Array.isArray(previousLevelItems)) {
      return false;
    }
    return previousLevelItems.some((item) => item.name.trim() !== "");
  };

  return (
    <div className="h-[100vh] bg-[rgba(256,256,256,1)]">
      {/* 導航按鈕 */}
      <div className="flex justify-between bg-gray-100 w-[276px] rounded m-4">
        <button
          onClick={() => handleButtonClick("general")}
          className={`px-2 py-1 flex-1 rounded ${selectedButton === "general" ? "bg-[#ADDEAC]" : "bg-gray-100"
            }`}
        >
          一般記憶
        </button>
        <button
          onClick={() => handleButtonClick("advanced")}
          className={`px-2 py-1 flex-1 rounded ${selectedButton === "advanced" ? "bg-[#ADDEAC]" : "bg-gray-100"
            }`}
        >
          進階記憶
        </button>
        <button
          onClick={() => handleButtonClick("qa")}
          className={`px-2 py-1 flex-1 rounded ${selectedButton === "qa" ? "bg-[#ADDEAC]" : "bg-gray-100"
            }`}
        >
          問答記憶
        </button>
      </div>

      {/* 模板名稱和品項輸入 */}
      <div className="flex justify-between mt-4 ml-4 mr-4">
        <div className="flex gap-9">
          <div>
            <span className="text-[14px]">模板名稱</span>
            <Row
              justify="center"
              className="w-[196px] mt-1"
              style={{ marginBottom: 20 }}
            >
              <Input
                placeholder="請輸入模板名稱"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </Row>
          </div>
        </div>
        <Row justify="center" className="gap-5">
          <Button onClick={() => setShowTemplateEditor(false)}>取消</Button>
          <Button type="primary" onClick={handleSaveTemplate}>
            儲存
          </Button>
        </Row>
      </div>

      {/* Template Structure Editing Area */}
      {loading ? <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div> : (
      <Row gutter={0} style={{ height: "100%" }}>
        {/* Level 1 */}
        <Col span={8} style={{ height: "100%" }}>
          <LevelSection
            level={1}
            items={structure || []} 
            onAdd={() => handleAddItem(1)}
            onChange={(level, index, value) =>
              handleLevelChange(level, index, value)
            }
            onDelete={(level, index) => handleDeleteItem(level, index)}
            onNextLevel={handleNextLevel}
            selectedItem={selectedItems[1]}
            setSelectedItem={(index) => handleSetSelectedItem(1, index)}
            show={showLevels[1]}
            previousLevelHasValue={checkPreviousLevelHasValue(1)}
          />
        </Col>

        {/* Level 2 */}
          <Col span={8} style={{ height: "100%" }}>
            <LevelSection
              level={2}
              items={getItemByPath(selectedPath.slice(0, 1), structure) || []}
              onAdd={() => handleAddItem(2)}
              onChange={(level, index, value) =>
                handleLevelChange(level, index, value)
              }
              onDelete={(level, index) => handleDeleteItem(level, index)}
              onNextLevel={handleNextLevel}
              selectedItem={selectedItems[2]}
              setSelectedItem={(index) => handleSetSelectedItem(2, index)}
              show={showLevels[2]}
              previousLevelHasValue={checkPreviousLevelHasValue(2)}
            />
          </Col>
       
        {/* Level 3 */}
          <Col span={8} style={{ height: "100%" }}>
            <LevelSection
              level={3}
              items={getItemByPath(selectedPath.slice(0, 2), structure) || []}
              onAdd={() => handleAddItem(3)}
              onChange={(level, index, value) =>
                handleLevelChange(level, index, value)
              }
              onDelete={(level, index) => handleDeleteItem(level, index)}
              onNextLevel={handleNextLevel}
              selectedItem={selectedItems[3]}
              setSelectedItem={(index) => handleSetSelectedItem(3, index)}
              show={showLevels[3]}
              previousLevelHasValue={checkPreviousLevelHasValue(3)}
            />
          </Col>
      </Row>
      )}
    </div>
  );
};

export default TemplateEditor;
